import { useState, useEffect } from 'react';

function getMobileOperatingSystem() {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  } catch (_error) {
    return 'unknown';
  }
}

export default function useMobileSystem() {
  const [device, setDevice] = useState('unknown');

  useEffect(() => {
    setTimeout(() => {
      setDevice(getMobileOperatingSystem());
    }, 0);
  }, []);

  return device;
}
